import { Injectable } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { NavigationActions } from '../../shared/actions/navigation.actions';
import { HotelActions } from '../actions/hotel.actions';
import { HotelService } from '../services/hotel.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HotelEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly hotelService: HotelService
  ) {}

  onSearchHotels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.searchHotels),
      switchMap(req =>
        this.hotelService.search(req.force, req.filterParams).pipe(
          map(searchOutput => {
            if (searchOutput) {
              return HotelActions.searchHotelsSuccess({
                searchOutput
              });
            }
          }),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.message }));
          })
        )
      )
    )
  );

  onGetHotel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.getHotel),
      switchMap(req =>
        this.hotelService
          .get(
            req.hotelCode,
            encodeURIComponent(req.hotelChainCode),
            req.supplier
          )
          .pipe(
            map(hotel =>
              HotelActions.getHotelSuccess({
                hotel
              })
            ),
            catchError(error =>
              of(ToastActions.errorToast({ message: error.message }))
            )
          )
      )
    )
  );

  onUpsertHotelNavigateBackOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.upsertHotelNavigateBackOnSuccess),
      switchMap(req => {
        return this.hotelService.upsert(req.hotel).pipe(
          switchMap(_ => [
            HotelActions.upsertHotelSuccess(),
            NavigationActions.navigateBack()
          ]),
          catchError(error =>
            of(ToastActions.errorToast({ message: error.message }))
          )
        );
      })
    )
  );

  onUpsertHotel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.upsertHotel),
      switchMap(req => {
        return this.hotelService.upsert(req.hotel).pipe(
          map(_ => HotelActions.upsertHotelSuccess()),
          catchError(error =>
            of(
              ToastActions.errorToast({
                message: (error as HttpErrorResponse).error
              })
            )
          )
        );
      })
    )
  );

  onSuccessfulUpsertHotel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.upsertHotelSuccess),
      switchMap(_ => [
        ToastActions.successToast({
          message: 'Hotel saved'
        })
      ])
    )
  );

  onDeleteHotel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.deleteHotels),
      switchMap(req =>
        this.hotelService.delete(req.deleteHotelRequest).pipe(
          switchMap(_ => [
            HotelActions.deleteHotelSuccess(),
            ToastActions.successToast({
              message: 'Hotel(s) deleted.'
            }),
            HotelActions.searchHotels({
              force: true
            })
          ]),
          catchError(error =>
            of(ToastActions.errorToast({ message: error.message }))
          )
        )
      )
    )
  );

  onDeleteContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.deleteContent),
      switchMap(req =>
        this.hotelService
          .deleteContent(req.hotelCode, req.hotelChainCode, req.supplier)
          .pipe(
            switchMap(_ => [
              HotelActions.deleteHotelSuccess(),
              ToastActions.successToast({
                message: 'Content deletion has been started - please wait.'
              }),
              HotelActions.searchHotels({
                force: true
              })
            ]),
            catchError(error =>
              of(ToastActions.errorToast({ message: error.message }))
            )
          )
      )
    )
  );

  validateHotel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.validateHotel),
      mergeMap(({ hotelCode, hotelChainCode, supplier }) =>
        this.hotelService.exists(hotelCode, hotelChainCode, supplier).pipe(
          mergeMap(response => {
            if (response) {
              const errorMessage =
                'This hotel code already exists for this hotelchain/supplier. Please provide another hotel code.';
              return [
                HotelActions.validateHotelFailure({
                  errorMessage: errorMessage
                }),
                ToastActions.errorToast({
                  message: errorMessage
                }),
                HotelActions.setSaveButtonDisabled({
                  isDisabled: response
                })
              ];
            }
            return of(
              HotelActions.setSaveButtonDisabled({
                isDisabled: response
              })
            );
          })
        )
      )
    )
  );

  setSaveButtonDisabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelActions.setSaveButtonDisabled),
      map(HotelActions.setSaveButtonDisabledSuccess)
    )
  );
}
