import { DsaMenu, DsaApplicationMenuItems } from '@dsa/design-system-angular';

export const MENU_HOME: DsaMenu = {
  label: 'Home',
  selectable: true,
  path: ''
};

export const MENU_BOOKING: DsaMenu = {
  label: 'Booking',
  selectable: false,
  items: [
    {
      label: 'Mapping',
      selectable: false,
      items: [
        {
          label: 'Hotel',
          path: 'booking/mapping/hotel'
        },
        {
          label: 'Mealplan',
          path: 'booking/mapping/meal-plan'
        },
        {
          label: 'Room Type',
          path: 'booking/mapping/room-type'
        },
        {
          label: 'Client',
          path: 'booking/mapping/client'
        },
        {
          label: 'Vehicle',
          path: 'booking/mapping/vehicle'
        },
        {
          label: 'Sender Mail',
          path: 'booking/mapping/sender-mail'
        }
      ]
    },
    {
      label: 'Store',
      selectable: false,
      items: [
        {
          label: 'Received Bookings',
          path: 'booking/storage/received'
        }
      ]
    }
  ]
};

export const MENU_EXCEPTIONS_BOOKING: DsaMenu = {
  label: 'Exception',
  selectable: false,
  items: [
    {
      label: 'Overview',
      selectable: false,
      items: [
        {
          label: 'Booking',
          path: 'exception/booking'
        },
        {
          label: 'History',
          path: 'exception/history'
        }
      ]
    }
  ]
};

export const MENU_HOME_DYNAMIC_CONNECT: DsaMenu = {
  label: 'Home',
  selectable: true,
  path: 'dynamic-connect'
};

export const MENU_PORTFOLIO: DsaMenu = {
  label: 'Portfolio',
  path: 'dynamic-connect/',
  selectable: false,
  items: [
    {
      label: 'Hotel Chains',
      path: 'dynamic-connect/hotel-chains'
    },
    {
      label: 'Hotels',
      path: 'dynamic-connect/hotels'
    }
  ]
};

export const MENU_EXCEPTIONS_BUSINESS: DsaMenu = {
  label: 'Exceptions',
  path: 'dynamic-connect/exceptions',
  selectable: true,
  items: []
};

export const MENU_BULK_EDIT: DsaMenu = {
  label: 'Bulk Edit',
  selectable: false,
  items: [
    {
      label: 'Activate Hotels',
      path: 'dynamic-connect/bulk-edit/activate-hotels'
    },
    {
      label: 'Deactivate Hotels',
      path: 'dynamic-connect/bulk-edit/deactivate-hotels'
    },
    {
      label: 'Delete Hotels',
      path: 'dynamic-connect/bulk-edit/delete-hotels'
    }
  ]
};

export const MENU_MAPPING: DsaMenu = {
  label: 'Mapping',
  path: 'dynamic-connect/mapping',
  selectable: false,
  items: [
    {
      label: 'ExternalMapping',
      path: 'dynamic-connect/mapping/externalmapping'
    }
  ]
};

export const APPLICATION_MENU: DsaApplicationMenuItems = {
  items: [MENU_HOME, MENU_BOOKING, MENU_EXCEPTIONS_BOOKING]
};

export const APPLICATION_MENU_DYNAMIC_CONNECT: DsaApplicationMenuItems = {
  items: [
    MENU_HOME_DYNAMIC_CONNECT,
    MENU_EXCEPTIONS_BUSINESS,
    MENU_PORTFOLIO,
    MENU_MAPPING,
    MENU_BULK_EDIT
  ]
};
