import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  DsaApplicationTitleModule,
  DsaBackToTopButtonComponent,
  DsaBackToTopButtonModule,
  DsaCardModule,
  DsaFooterComponent,
  DsaFooterModule,
  DsaNavigationbarModule,
  DsaPersonalMenuModule
} from '@dsa/design-system-angular';
import { DsaApplicationNavigationModule } from '@dsa/design-system-angular/application-navigation';
import { DsaFeedbackFormModule } from '@dsa/design-system-angular/feedback-form';
import { DsaIconModule } from '@dsa/design-system-angular/icon';
import { JobNotificationsDisplayModule } from '../dynamic-connect/job-notifications-display/job-notifications-display.module';
import { windowProvider, WindowToken } from '../shared/providers/window';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { GreetingComponent } from './components/greeting/greeting.component';
import { InstanaComponent } from './components/instana/instana.component';
import { ServiceDeskFeedbackComponent } from './components/service-desk-feedback/service-desk-feedback.component';
import { AccessDeniedComponent } from './container/access-denied/access-denied.component';
import { StartPageComponent } from './container/startpage/startpage.component';
import { HasRolePipe } from './pipes/has-role.pipe';

@NgModule({
  declarations: [
    AccessDeniedComponent,
    HasRolePipe,
    ServiceDeskFeedbackComponent,
    InstanaComponent,
    AppHeaderComponent,
    GreetingComponent,
    StartPageComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    DsaApplicationNavigationModule,
    DsaApplicationTitleModule,
    DsaPersonalMenuModule,
    DsaNavigationbarModule,
    DsaFeedbackFormModule,
    DsaFooterModule,
    DsaBackToTopButtonModule,
    DsaIconModule,
    DsaCardModule,
    JobNotificationsDisplayModule
  ],
  exports: [
    DsaCardModule,
    ServiceDeskFeedbackComponent,
    InstanaComponent,
    HasRolePipe,
    AppHeaderComponent,
    DsaFooterComponent,
    DsaFeedbackFormModule,
    DsaBackToTopButtonComponent,
    GreetingComponent
  ],
  providers: [{ provide: WindowToken, useFactory: windowProvider }]
})
export class CoreModule {}
