export const environment = {
  production: true,
  name: 'Production',
  sentryDsn:
    'https://3e579cb049904ab9a23befb5aec6e0ef@o585713.ingest.sentry.io/5747195',
  instanaKey: 'Z4BSbnWvTOqxtjCa-NO9Mg',
  authConfig: {
    loginUrl:
      'https://identity.core.travel/auth/realms/IdentityCore/protocol/openid-connect/auth',
    issuer: 'https://identity.core.travel/auth/realms/IdentityCore',
    redirectUri: 'https://der.core.travel/',
    clientId: 'ceco',
    silentRefreshRedirectUri:
      window.location.origin + '/assets/oauth2-silent-refresh.html'
  },
  defaultDataServiceConfig: {
    root: '',
    timeout: 3000
  },
  apiEndpointsConfig: {
    bookings: 'https://booking.core.travel/api/bookings',
    bookingMappings: 'https://booking.core.travel/api/mappings',
    exception: 'https://exception.core.travel/api',
    requests: 'https://booking.core.travel/api/requests',
    masterdata: 'https://masterdata.core.travel/api',
    dynamicConnect: 'https://ui-dynamic-connect.core.travel/api'
  },
  powerBi: {
    reportUrl:
      'https://app.powerbi.com/reportEmbed?reportId=5451736a-ed8d-4524-8647-48b2903137a8&autoAuth=true&ctid=f10d619e-6154-45e4-9879-283640920dca&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1jLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  },
  serviceDeskFeedbackEmail: 'dmc.support@dertouristik.com'
};
